import CardActivityChart from "~/components/shared/ActivityChart";
import GenerateSharedActivityReportButton from "~/components/target/shared/details/GenerateSharedActivityReportButton";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type RepoEvaluation } from "@openqlabs/drm-db";
import WaitingForFirstEvaluation from "~/components/campaigns/MainPage/Cards/SharedCard/WaitingForFirstEvaluation";

export default function ChangesTab() {
  const latestEvaluation = useLatestEvaluation<RepoEvaluation>();
  if (!latestEvaluation?.result) {
    return <WaitingForFirstEvaluation />;
  }

  return (
    <div className="pt-6">
      <CardActivityChart />
      <div className="flex items-center justify-center space-x-6 py-6">
        <GenerateSharedActivityReportButton type="repo" title="Commit Summary">
          Summary
        </GenerateSharedActivityReportButton>
      </div>
    </div>
  );
}
