import SecondaryInsightsDashboards from "./Dashboards";

const Conditions = ({
  userCount,
  leads,
  newLeads,
  customers,
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  return (
    <div>
      {userCount > 0 && (
        <SecondaryInsightsDashboards
          userCount={userCount}
          leads={leads}
          newLeads={newLeads}
          customers={customers}
        />
      )}
    </div>
  );
};

export default Conditions;
