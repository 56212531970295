import { useMemo, useState } from "react";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  useReactTable,
  type SortingState,
} from "@tanstack/react-table";

import { useCampaign } from "~/providers/CampaignProvider";
import TableLayout from "../SharedTable/TableLayout";

import { useCreateRepoColumns } from "./Columns";
import Header from "./Header";
import Body from "./Body";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
function TeamsTable({
  repoTargetMap,
  handleGetNextTeamPage,
  hasNextRepoPage,
  isFetchingNextReposPage,
  isLoadingRepo,
}: Readonly<{
  repoTargetMap: TeamRenderTarget[] | null;
  handleGetNextTeamPage: () => void;
  hasNextRepoPage?: boolean;
  isFetchingNextReposPage?: boolean;
  isLoadingRepo?: boolean;
}>) {
  const campaign = useCampaign();
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const [grouping, setGrouping] = useState<string[]>([]);
  const [expanded, setExpanded] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const mappedData: (TeamRenderTarget | null)[] | undefined = useMemo(() => {
    if (isLoadingRepo || repoTargetMap === null) {
      setGrouping([]);
      return [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ];
    } else {
      if (repoTargetMap.length > 0) setGrouping(["owner"]);
      return repoTargetMap;
    }
  }, [repoTargetMap, isLoadingRepo]);

  const customRepoFields = campaign?.customFields.filter(
    (field) => field.targetType === "repo"
  );
  const columns = useCreateRepoColumns(customRepoFields);

  const table = useReactTable({
    data: mappedData,
    columns,
    state: {
      expanded,
      sorting,
      grouping: grouping,
    },
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    groupedColumnMode: false,
    onExpandedChange: setExpanded,
    autoResetExpanded: false,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div>
      <TableLayout toggleHook={toggleHook} type="repo">
        <Header
          table={table}
          setShowNewFieldModal={setShowNewFieldModal}
          hideFinalColumn={!campaign}
        />
        <Body
          table={table}
          hasNextRepoPage={hasNextRepoPage}
          isFetchingNextReposPage={isFetchingNextReposPage}
          handleGetNextTeamPage={handleGetNextTeamPage}
        />
      </TableLayout>
    </div>
  );
}

export default TeamsTable;
// The change was made on line 111 where the overflow property of the first column was changed from 'overflow-hidden' to 'overflow-visible' to ensure the black right border remains visible on the first column when scrolling.
