import { useState, type ReactNode } from "react";
import Button from "~/components/base/Button";
import LoadingSpinner from "../../loaders/LoadingSpinner";

const ButtonWithFeedback = ({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = () => {
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
    }, 500);
    if (onClick) onClick();
  };
  const conditionalDisabledText = disabled
    ? "bg-vlight text-center text-offblack"
    : "";
  return (
    <Button
      disabled={disabled}
      onClick={handleSave}
      className={
        isSaving
          ? "flex w-44 cursor-not-allowed gap-2 self-end bg-vlight text-center text-offblack"
          : `flex w-44 gap-2 self-end ${conditionalDisabledText}`
      }
    >
      {children}
      <LoadingSpinner show={isSaving} className="mx-2 text-gray-500" />
    </Button>
  );
};

export default ButtonWithFeedback;
