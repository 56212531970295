import { useCampaign } from "~/providers/CampaignProvider";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type RepoEvaluation } from "@openqlabs/drm-db";
import WaitingForFirstEvaluation from "~/components/campaigns/MainPage/Cards/SharedCard/WaitingForFirstEvaluation";
import Developer from "~/components/campaigns/MainPage/Cards/RepoCard/tabs/Contributors/Developer";
import { api } from "~/utils/api";

export default function DevelopersTab() {
  const campaign = useCampaign();
  const latestEvaluation = useLatestEvaluation<RepoEvaluation>();
  const { data: targetContact } =
    api.targetContact.getTargetContactByGithubName.useQuery({
      campaignId: campaign?.id,
      teamAccountId: latestEvaluation?.teamAccountId,
      githubName: latestEvaluation?.params?.login,
    });

  if (!latestEvaluation) {
    return <WaitingForFirstEvaluation />;
  }
  return (
    <div>
      <div className="flex flex-col text-lg font-semibold text-black">
        The following contributors are working on this repository.
      </div>
      {campaign && (
        <div className="py-3 text-lg">
          Add a Contributor to your campaign contacts to learn more about them.
          This will allow you to analyze each one and search for relevant
          activities.
        </div>
      )}
      <div className="space-y-6 divide-y-2">
        {targetContact?.contributors?.map((author) => (
          <Developer key={author.login} mentionableUser={author} />
        ))}
      </div>
    </div>
  );
}
