import Link from "next/link";
import DashboardCard from "~/components/campaigns/MainPage/Dashboard/DashboardCard";
import { useCampaign } from "~/providers/CampaignProvider";

const KPIs = ({
  setSubMenu,
  leads,
  newLeads,
  customers,
}: {
  setSubMenu: (value: string) => void;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const campaign = useCampaign();

  const { budget } = campaign;

  const activeUsers = leads + customers + newLeads;
  const costPerCampaignLead = `${
    leads === 0 ? "N/A" : `$${(budget / leads).toFixed(0)}`
  }`;
  const costPerLead = `${
    newLeads + leads === 0
      ? "N/A"
      : `$${(budget / (newLeads + leads)).toFixed(0)}`
  }`;
  const campaignLeadTitle = `${costPerCampaignLead}`;
  const leadTitle = `${costPerLead}`;
  const costPerActiveUser = (budget / activeUsers).toFixed(0);
  const noActiveUsers = activeUsers === 0;
  const activeUsersTitle = noActiveUsers ? "N/A" : `$${costPerActiveUser}`;

  const today = new Date();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  const campaignEndDate = campaign?.endDate ?? campaign?.createdAt;
  const oneMonthPassed =
    today.getTime() - campaignEndDate.getTime() > thirtyDays;

  return (
    <div>
      <h3 className="pt-6 text-xl font-semibold">KPIs</h3>
      <div className="pb-6 pt-2">
        KPIs are based on Secondary Insights which reflect the current status of
        your contributors&lsquo; engagement.
      </div>
      {oneMonthPassed && (
        <div className="flex flex-wrap gap-6 ">
          <DashboardCard
            leadText="Direct leads"
            leads={campaignLeadTitle}
            metricName="Cost per campaign lead"
            tooltipText="Cost per lead generated during the campaign period"
          />
          <DashboardCard
            leadText="All leads"
            leads={leadTitle}
            metricName="Cost per lead"
            tooltipText="Cost per lead generated since the campaign start, including post-campaign leads"
          />
          <DashboardCard
            leadText="Leads & customers"
            leads={activeUsersTitle}
            metricName="Cost per active user"
          />
        </div>
      )}
      {!oneMonthPassed && (
        <>
          Allow a one-month period to elapse following the end date of the
          campaign before gathering additional insights.
        </>
      )}
      <div className="pt-6">
        Setup a budget{" "}
        <button onClick={() => setSubMenu("Settings")} className="text-accent">
          here
        </button>{" "}
        and start{" "}
        <Link
          href={`/campaigns/${campaign.id}/dependency-tracking`}
          className="text-accent"
        >
          dependency tracking
        </Link>{" "}
        in order to calculate leads.
      </div>
    </div>
  );
};
export default KPIs;
