import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { useCampaign } from "~/providers/CampaignProvider";
import { useCreateUserColumns } from "./Columns";
import Header from "./Header";
import Body from "./Body";
import TableLayout from "../SharedTable/TableLayout";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
import { api } from "~/utils/api";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

function UsersTable({
  userTargetMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage,
  isLoadingUsers,
  showEngagement,
}: Readonly<{
  userTargetMap: TeamRenderTarget[] | null;
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
  isLoadingUsers: boolean;
  showEngagement: boolean;
}>) {
  const campaign = useCampaign();
  const { activeTeamAccount } = useTeamAccount();

  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const mappedData = useMemo(() => {
    return isLoadingUsers || !userTargetMap
      ? [null, null, null, null, null, null, null, null, null, null, null, null]
      : userTargetMap;
  }, [userTargetMap, isLoadingUsers]);
  const customUserFields = campaign?.customFields.filter(
    (field) => field.targetType === "user"
  );
  const { data: hasConnectedSocial } =
    api.targetContact.campaignOrTeamHasConnectedSocials.useQuery({
      campaignId: campaign?.id,
      teamAccountId: activeTeamAccount?.id,
    });
  const columns = useCreateUserColumns(
    customUserFields,
    showEngagement,
    hasConnectedSocial
  );

  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <TableLayout toggleHook={toggleHook} type="user">
        <Header<TeamRenderTarget>
          table={table}
          setShowNewFieldModal={setShowNewFieldModal}
          hideFinalColumn={!campaign}
        />
        <Body<TeamRenderTarget>
          table={table}
          hasNextUserPage={hasNextUserPage}
          isFetchingNextUsersPage={isFetchingNextUsersPage}
          handleGetNextUserPage={handleGetNextUserPage}
          hideFinalColumn={!campaign}
        />
      </TableLayout>
    </div>
  );
}
export default UsersTable;
