import { useEffect, useState } from "react";
import {
  getLocalDateString,
  validateDateFormat,
  getEightMonthsAgo,
} from "~/lib/date";
import { useCampaign, useCampaignRefetch } from "~/providers/CampaignProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { TrackerProvider } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import EvalSummaryModal from "../EvalSummaryModal";
import ButtonWithFeedback from "../base/ButttonWithFeedBack";
import Input from "../base/Input";
import Label from "../base/Label";
import SuccessModal from "../base/SuccessModal";
import { Enums } from "@openqlabs/utils";

const Settings = () => {
  const campaign = useCampaign();
  const logger = useLogger();
  const refetch = useCampaignRefetch();
  const [changingDates, setChangingDates] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const { mutate } = api.campaign.campaignEdit.useMutation({
    onSuccess: () => {
      refetch().catch((err) => logger.error(err, "Settings.tsx"));
      if (changingDates) setShowModal(true);
    },
  });
  const { mutate: setCampaignEvalToWaiting } =
    api.campaignEvaluation.setToWaiting.useMutation({
      onSuccess: () => {
        /* add success modal */
      },
    });
  const [showModal, setShowModal] = useState(false);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);
  const [cutoffDateValid, setCutoffDateValid] = useState(true);
  const { activeTeamAccount } = useTeamAccount();
  const [teamAccountUser] = useTeamAccountUser();
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cutoffDate, setCutoffDate] = useState("");
  const [changingMentionableUsers, setChangingMentionableUsers] =
    useState(false);
  const [mentionableUsers, setMentionableUsers] = useState(
    campaign?.mentionableUsers ?? false
  );

  const [maxRepoSize, setMaxRepoSize] = useState(campaign?.maxRepoSize ?? 250);
  const [showMaxRepoSizeError, setShowMaxRepoSizeError] = useState(false);

  const handleMaxRepoSizeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSize = parseInt(event.target.value);
    setMaxRepoSize(newSize);
    if (isNaN(newSize) || newSize < 250 || newSize > 1024) {
      setShowMaxRepoSizeError(true);
    } else {
      setShowMaxRepoSizeError(false);
    }
  };

  const campaignEvaluationDate = api.campaignEvaluation.getUpdatedAt.useQuery({
    campaignId: campaign.id,
    teamAccountId: activeTeamAccount.id,
  }).data;

  useEffect(() => {
    const campaignStartDateFormatted = getLocalDateString(
      campaign?.startDate ? new Date(campaign?.startDate) : new Date()
    );
    const campaignEndDateFormatted = campaign?.endDate
      ? getLocalDateString(new Date(campaign?.endDate))
      : "";
    const campaignCutoffDateFormatted = campaign?.cutoffDate
      ? getLocalDateString(new Date(campaign?.cutoffDate))
      : getLocalDateString(
          getEightMonthsAgo(new Date(campaignEvaluationDate ?? new Date()))
        );
    setBudget(campaign?.budget?.toString() ?? "");
    setMaxRepoSize(campaign?.maxRepoSize ?? 250);
    setStartDate(campaignStartDateFormatted);
    setEndDate(campaignEndDateFormatted);
    setCutoffDate(campaignCutoffDateFormatted);
    setMentionableUsers(campaign?.mentionableUsers ?? false);
  }, [campaign]);

  const handleUpdateCampaign = () => {
    if (!endDateValid || !startDateValid || !cutoffDateValid) {
      return;
    }
    if (!campaign) return;
    mutate({
      id: campaign.id,
      budget: parseInt(budget),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      cutoffDate: new Date(cutoffDate),
      teamAccountId: activeTeamAccount?.id,
      mentionableUsers: mentionableUsers,
      maxRepoSize: showMaxRepoSizeError ? 250 : maxRepoSize,
    });
    if (changingMentionableUsers && mentionableUsers) {
      setCampaignEvalToWaiting({
        campaignId: campaign.id,
        teamAccountId: activeTeamAccount.id,
      });

      setSuccessModal(true);
    }
  };

  const handleEndDateChange = (value: string) => {
    setChangingDates(true);
    setEndDateValid(validateDateFormat(value));
    setEndDate(value);
  };
  const handleStartDateChange = (value: string) => {
    setChangingDates(true);
    setStartDateValid(validateDateFormat(value));
    setStartDate(value);
  };
  const handleCutoffDateChange = (value: string) => {
    setChangingDates(true);
    setCutoffDateValid(validateDateFormat(value));
    setCutoffDate(value);
  };
  const handleMentionableUsersChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMentionableUsers(e.target.checked);
    setChangingMentionableUsers(true);
  };

  return (
    <div className="h-[calc(100vh_-_270px)]  overflow-scroll">
      <div className="flex flex-col gap-8 border-b border-divider pb-8">
        <div>
          <Label>Budget</Label>
          <Input value={budget} setValue={setBudget} />
        </div>
        <div>
          <Label>Maximum Repository Size (MB)</Label>
          <div className="pb-4">
            By default, we allow a maximum size of 250 MB for repositories. You
            can increase this limit to up to 1 GB in order to be able to add
            larger repositories to your campaign.
          </div>
          <input
            type="range"
            min={250}
            max={1024}
            onChange={handleMaxRepoSizeChange}
            value={maxRepoSize}
          />
          <p>{maxRepoSize} MB</p>
          {showMaxRepoSizeError && (
            <p className="text-red-500">
              Repository size must be between 250MB and 1GB
            </p>
          )}
        </div>
        <div>
          <Label>Users</Label>
          <div className="pb-4">
            By default, for repositories that are too large, we add all
            assignable users to your campaign. By selecting this option, we will
            also add all mentionable users from large repositories to your
            campaign.
          </div>
          <div className="flex gap-2">
            <label htmlFor="showRepos">
              Add mentionable users from large repositories to the campaign
            </label>
            <input
              id="addMentionableUsers"
              onChange={handleMentionableUsersChange}
              checked={mentionableUsers}
              type="checkbox"
              className="accent-accent"
            />
          </div>
        </div>
        {!(campaign?.mode == Enums.CampaignMode.LIGHT) && (
          <>
            <div>
              <Label>Start Date</Label>
              <Input value={startDate} setValue={handleStartDateChange} />
              {!startDateValid && (
                <p className="text-red-500">Invalid date format</p>
              )}
            </div>
            <div>
              <Label>End Date</Label>
              <Input value={endDate} setValue={handleEndDateChange} />
              {!endDateValid && (
                <p className="text-red-500">Invalid date format</p>
              )}
            </div>
            <div>
              <Label>Inactivity period for contributor status analysis</Label>
              <p className="mb-2">
                Repositories which have been inactive for more than 8 months (as
                of evaluation date) are excluded from the contributor dependency
                status analysis per default. <br />
                Depending on this cutoff date and the campaign start date, some
                contributors could show as "lead" or "new lead" when they
                actually might have already been using the dependency before the
                campaign started.
              </p>
              <Input value={cutoffDate} setValue={handleCutoffDateChange} />
              {!cutoffDateValid && (
                <p className="text-red-500">Invalid date format</p>
              )}
            </div>
            <p>
              After updating the start, end or cutoff date here, you{"'"}ll have
              to manually re-run old evaluations which you want to use the
              newtime window. Unfortunately automatically re-running the
              evaluations whenever the time window is updated could lead to
              excessive resource usage.
            </p>
          </>
        )}
        <ButtonWithFeedback
          disabled={
            !(endDateValid && startDateValid) /* || showMaxRepoSizeError */
          }
          onClick={handleUpdateCampaign}
        >
          Save
        </ButtonWithFeedback>
      </div>
      {showModal && (
        <TrackerProvider initialTracker={campaign.trackers[0]}>
          <EvalSummaryModal
            waitForTracker={true}
            setShowModal={setShowModal}
            showModal={showModal}
            campaign={campaign}
            userId={teamAccountUser?.id}
            teamAccountId={activeTeamAccount.id}
            redirect={`/campaigns/${campaign.id}`}
          />
        </TrackerProvider>
      )}
      {successModal && (
        <SuccessModal
          successText={`Mentionable users are being added to the campaign.`}
          setSuccessModal={setSuccessModal}
        />
      )}
    </div>
  );
};
export default Settings;
