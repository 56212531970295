import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import Header from "../UsersTable/Header";
import Body from "../UsersTable/Body";
import TableLayout from "../SharedTable/TableLayout";
import { ultraLightColumns as columns } from "./Columns";
import type { UltraLightContact } from "node_modules/@openqlabs/drm-db/generated/.prisma/client";

function UltraLightTable({
  userTargetMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage,
  isLoadingUsers,
}: Readonly<{
  userTargetMap: UltraLightContact[] | null;
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
  isLoadingUsers: boolean;
}>) {
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const mappedData = useMemo(() => {
    return isLoadingUsers || !userTargetMap
      ? [null, null, null, null, null, null, null, null, null, null, null, null]
      : userTargetMap;
  }, [userTargetMap, isLoadingUsers]);

  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <TableLayout toggleHook={toggleHook} type="user">
        <Header<UltraLightContact | null>
          table={table}
          setShowNewFieldModal={setShowNewFieldModal}
          hideFinalColumn={true}
        />
        <Body<UltraLightContact | null>
          table={table}
          hasNextUserPage={hasNextUserPage}
          isFetchingNextUsersPage={isFetchingNextUsersPage}
          handleGetNextUserPage={handleGetNextUserPage}
          hideFinalColumn={true}
          hideFinalRow={true}
        />
      </TableLayout>
    </div>
  );
}
export default UltraLightTable;
