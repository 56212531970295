import { api } from "~/store/trpc";

import useCampaignListParams from "./useCampaignListParams";

const useRemoveItemFromCampaignList = (type: "repo" | "user") => {
  const utils = api.useUtils();
  const tableParams = useCampaignListParams();
  const filterOutTargetContact = (targetContactId: string) => {
    utils.targetContact.getTargetsByCampaignId.setInfiniteData(
      tableParams[type],
      (data) => {
        const newPages = data?.pages?.map((page) => {
          const newItems = page.items.filter((item) => {
            return item.id !== targetContactId;
          });
          return { ...page, items: newItems };
        });
        const result = {
          ...data,
          pages: newPages ?? [],
          cursor: null,
          pageParams: [null],
        };

        return result;
      }
    );
  };
  return filterOutTargetContact;
};

export default useRemoveItemFromCampaignList;
