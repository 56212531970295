import DiscreetButton from "~/components/base/DiscreetButton";
import { Footer } from "~/components/layout/Card";
import { CardTabs } from "..";
import ChangesTab from "./Changes";
import DependenciesTab from "./Dependencies";
import DevelopersTab from "./Contributors";
import { decodeStatusAsObject } from "@openqlabs/utils";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
import { TargetContactProvider } from "~/providers/TargetContactProvider";
import TabWrapper from "../../SharedCard/tabs/TabWrapper";

export default function Tabs({
  handleClickTab,
  showTab,
  targetContact,
}: Readonly<{
  handleClickTab: (tab: CardTabs) => void;
  showTab: CardTabs | null;
  targetContact: NonNullable<TeamRenderTarget>;
}>) {
  const dependencyStatus = decodeStatusAsObject(
    targetContact.dependencyStatusNumber,
    targetContact.manualStatus
  );

  return (
    <Footer>
      <div className="flex">
        <DiscreetButton
          className={`w-full !rounded-b-none !rounded-tl-none !border-none text-sm font-normal hover:bg-offwhite ${
            showTab === CardTabs.Changes
              ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 "
              : ""
          }`}
          onClick={() => handleClickTab(CardTabs.Changes)}
        >
          Changes
        </DiscreetButton>

        <DiscreetButton
          className={`w-full !rounded-b-none !border-none text-sm font-normal hover:!bg-offwhite ${
            showTab === CardTabs.Dependencies
              ? " bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 "
              : ""
          }`}
          onClick={() => handleClickTab(CardTabs.Dependencies)}
        >
          Dependencies{" "}
          <span
            className={`mx-2 whitespace-nowrap rounded-md ${dependencyStatus.color} px-4 text-sm`}
          >
            {dependencyStatus.status}
          </span>
        </DiscreetButton>

        <DiscreetButton
          className={`w-full !rounded-b-none !rounded-tr-none !border-none text-sm font-normal hover:!bg-offwhite ${
            showTab === CardTabs.Developers
              ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300"
              : ""
          }`}
          onClick={() => handleClickTab(CardTabs.Developers)}
        >
          Developers
        </DiscreetButton>
      </div>

      <div
        className={` overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}
      >
        {!!showTab && (
          <TargetContactProvider id={targetContact.id}>
            <TabWrapper type="repo">
              <>
                {CardTabs.Developers === showTab && (
                  <div className="p-6">
                    <DevelopersTab />
                  </div>
                )}
                {CardTabs.Dependencies === showTab && <DependenciesTab />}
                {CardTabs.Changes === showTab && <ChangesTab />}
              </>
            </TabWrapper>
          </TargetContactProvider>
        )}
      </div>
    </Footer>
  );
}
