import DashboardCard from "../../DashboardCard";

const SecondaryInsightsDashboards = ({
  userCount,
  leads,
  newLeads,
  customers,
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const activeUsers = leads + customers + newLeads;

  return (
    <div>
      <div className="flex flex-wrap gap-6 ">
        <DashboardCard
          leadText="Active direct leads"
          percentage={((leads / userCount) * 100).toFixed(2).toString() + "%"}
          metricName="Converted campaign leads"
          leads={leads.toString()}
          tooltipText="Leads generated during the campaign period who are still active"
        />
        <DashboardCard
          leadText="Active follow-up leads"
          percentage={
            ((newLeads / userCount) * 100).toFixed(2).toString() + "%"
          }
          metricName="Converted post-campaign leads"
          leads={newLeads.toString()}
          tooltipText="Leads generated after the campaign ended who are still active"
        />
        <DashboardCard
          leadText="Active returning customers"
          percentage={
            ((customers / userCount) * 100).toFixed(2).toString() + "%"
          }
          metricName="Active returning users"
          leads={customers.toString()}
        />
        <DashboardCard
          leadText="All active leads & customers"
          percentage={
            ((activeUsers / userCount) * 100).toFixed(2).toString() + "%"
          }
          metricName="Total active users"
          leads={activeUsers.toString()}
        />
      </div>
    </div>
  );
};
export default SecondaryInsightsDashboards;
