import { useRouter } from "next/router";
import { useCampaignDeleteTarget } from "~/providers/CampaignProvider";

import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

import CardHeaderDefault from "~/components/campaigns/MainPage/Cards/SharedCard/CardHeader";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";

import { api } from "~/utils/api";

export default function RepoCardHeader({
  targetContact,
  setShowCard,
}: Readonly<{
  setShowCard: () => void;
  targetContact: NonNullable<TeamRenderTarget>;
}>) {
  const { activeTeamAccount } = useTeamAccount();
  const deleteTarget = useCampaignDeleteTarget();
  const { mutate: reEvaluateTargetContact } =
    api.evaluation.reEvaluateIndividual.useMutation();
  const logger = useLogger();

  const router = useRouter();

  const selectItem = async (name: string) => {
    if (name === "Settings") {
      router.push("/settings").catch((err) => logger.error(err, "Header.tsx"));
      return;
    }
    if (name === "Evaluate") {
      reEvaluateTargetContact({
        targetContactId: targetContact.id,
        teamAccountId: activeTeamAccount.id,
        campaignId: targetContact.campaignId as string,

        type: targetContact.type as "user" | "repo",
      });
    }
    if (name === "Delete") {
      setShowCard();
      deleteTarget(
        targetContact.githubTargetId,
        targetContact.id,
        targetContact.type as "user" | "repo"
      );
    }
  };

  const targetObj = {
    id: targetContact.id,
    nameWithOwner: targetContact.githubName,
    avatarUrl: targetContact.ownerAvatarUrl ?? "/hero.png",
    type: "repo",
    githubName: targetContact.githubName,
    manualStatus: targetContact.manualStatus ?? null,
  };
  return (
    <CardHeaderDefault
      selectItem={selectItem}
      targetObj={targetObj}
      targetLink={`/target/repo/${targetContact.teamTargetContactId}/commits/activity`}
    />
  );
}
