import { InfoIcon } from "@primer/octicons-react";
import { useState } from "react";
import RankList from "./DashboardInsights/RankList";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import {
  PersonIcon,
  Half2Icon,
  LightningBoltIcon,
  ShadowInnerIcon,
} from "@radix-ui/react-icons";
import { DollarSignIcon } from "lucide-react";

type IconType =
  | "Person"
  | "Half2"
  | "LightningBolt"
  | "DollarSign"
  | "ShadowInner"
  | "TwoPerson";

const iconComponents = {
  Person: PersonIcon,
  Half2: Half2Icon,
  LightningBolt: LightningBoltIcon,
  ShadowInner: ShadowInnerIcon,
  DollarSign: DollarSignIcon,
  TwoPerson: ({ className }: { className?: string }) => (
    <div className="flex items-end">
      <PersonIcon
        className={className}
        style={{ width: "0.8em", height: "0.8em", marginRight: "1px" }}
      />
      <PersonIcon className={className} />
    </div>
  ),
};

const DashboardCard = ({
  leads,
  percentage,
  metricName,
  leadText,
  tooltipText,
  rank,
  iconType,
}: {
  leads: string;
  percentage?: string;
  metricName: string;
  leadText: string;
  tooltipText?: string;
  rank?: string;
  iconType?: IconType;
}) => {
  const [showModal, setShowModal] = useState(false);

  const show = !!rank && !!parseInt(leads);
  const IconComponent = iconType
    ? iconComponents[iconType as keyof typeof iconComponents] || PersonIcon
    : PersonIcon;

  return (
    <Card className="flex-1">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex items-center">
          <CardTitle className="text-sm font-medium">{metricName}</CardTitle>
          {tooltipText && (
            <div className="group flex ml-2">
              <InfoIcon className="h-4 w-4 text-muted-foreground" />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute left-0 -top-8 whitespace-normal bg-white px-2 py-1 text-sm shadow-md rounded max-w-xs">
                  {tooltipText.split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      {index < tooltipText.split("\n").length - 1 && <br />}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <IconComponent className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{leads}</div>
        <p className="text-xs text-muted-foreground">
          {percentage || leadText}
        </p>
        {show && (
          <button
            className="text-accent mt-2 text-sm"
            onClick={() => setShowModal(true)}
          >
            Show Full Details
          </button>
        )}
      </CardContent>
      {showModal && show && (
        <RankList
          showModal={showModal}
          setShowModal={setShowModal}
          rank={rank}
        />
      )}
    </Card>
  );
};

export default DashboardCard;
