import Link from "next/link";

import Card, { Body, Summary } from "~/components/layout/Card";
import CardScores from "~/components/score/Scores";
import Followers from "~/components/campaigns/MainPage/Cards/SharedCard/summary/followers";
import Stars from "~/components/campaigns/MainPage/Cards/SharedCard/summary/stars";
import UserCardHeader from "./UserCardHeader";
import CardMembers from "./Members";
import Tabs from "./tabs/Tabs";
import useRemoveItemFromCampaignList from "../../SharedTable/hooks/useRemoveItemFromList";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function UserCard({
  targetContact,
}: {
  targetContact: NonNullable<TeamRenderTarget>;
}) {
  const handleRemoveUserFromCampaignList =
    useRemoveItemFromCampaignList("user");
  const handleSetShowCard = () => {
    handleRemoveUserFromCampaignList(targetContact.id);
  };
  if (!targetContact) return null;
  return (
    <Card>
      <UserCardHeader
        targetContact={targetContact}
        setShowCard={handleSetShowCard}
      />
      <Body>
        <Summary>
          <div>
            <div className="line-clamp-3">
              {targetContact.description ?? (
                <span className="opacity-50">No bio available</span>
              )}
            </div>
            {targetContact && (
              <Link
                href={`https://github.com/${targetContact.githubName}`}
                className="text-accent flex gap-1 items-center"
                target="_blank"
              >
                Read more
                <ArrowTopRightOnSquareIcon className="h-3 w-3 inline-block" />
              </Link>
            )}
          </div>
          <div className="flex gap-4">
            <Stars>{targetContact.totalStars}</Stars>

            <Followers>{targetContact.followers}</Followers>
          </div>
        </Summary>
        <CardMembers targetContact={targetContact} />

        <CardScores
          targetContact={targetContact}
          targetContactIsLoading={false}
        />
      </Body>
      <Tabs targetContact={targetContact} />
    </Card>
  );
}
