import { useTargetContact } from "~/providers/TargetContactProvider";
import { GithubTargetProvider } from "~/providers/GithubTargetProvider";
import { ReactNode } from "react";
import { UserTarget } from "@openqlabs/drm-db";
import { EvaluationProvider } from "~/providers/EvaluationProvider";
import { DepsEvaluationProvider } from "~/providers/DepsEvaluationProvider";
import useComprehensiveTargetData from "~/hooks/useComprehensiveTargetInfo";

const TabWrapper = ({
  children,
  type,
}: {
  children: ReactNode | ReactNode[];
  type: "repo" | "user";
}) => {
  const { targetContact } = useTargetContact();
  const targetContactWithChildren = useComprehensiveTargetData(
    type,
    targetContact
  );
  const githubEvaluation = targetContactWithChildren?.githubEvaluation;
  const githubDepsEvaluation = targetContactWithChildren?.githubDepsEvaluation;
  const dependenciesType =
    type === "repo" ? "repo-dependencies" : "user-dependencies";
  return (
    <GithubTargetProvider
      initialTarget={targetContact.githubTarget as UserTarget}
    >
      <EvaluationProvider evaluation={githubEvaluation} type="repo">
        <DepsEvaluationProvider
          depsEvaluation={githubDepsEvaluation}
          type={dependenciesType}
        >
          {children}
        </DepsEvaluationProvider>
      </EvaluationProvider>
    </GithubTargetProvider>
  );
};

export default TabWrapper;
