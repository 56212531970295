import { useMemo } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { InfoIcon } from "@primer/octicons-react";
import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  UserIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  type CellContext,
  type AccessorFnColumnDef,
} from "@tanstack/react-table";
import Link from "next/link";
import Image from "next/image";
import { getLangIcon } from "~/components/campaigns/MainPage/SharedTable/LanguageBadges";
import { getColorFromPercentage, getSimplifiedStatus } from "~/lib/utils";
import { decodeStatusAsObject, nukeNegatives } from "@openqlabs/utils";
import { trpc } from "~/store/trpc";
import { CellWithPill, LoadingWrapper } from "../Cells";
import { EditableCell, getInfoValueTeam } from "../TeamsTable/Columns";
import type { CustomField } from "~/server/api/model/campaign";
import { Skeleton, TruncatedString } from "../SharedTable/utils";
import UserSocials from "./UserSocials";
import { useCampaignRefetch } from "~/providers/CampaignProvider";
import { type TeamRenderTarget } from "~/providers/TargetContactProvider";

const columnHelper = createColumnHelper<TeamRenderTarget>();

const reputationHelper = (value: number) => {
  if (!value) return "N/A";
  return value?.toFixed() ?? "N/A";
};

const EmailHandler = ({ email }: { email: string }) => {
  return <Link href={`mailto:${email}`}>{email}</Link>;
};
const socialColumnsStatic = [
  columnHelper.accessor("discordUserName", {
    header: () => <span>Name</span>,
    cell: (info) => (
      <div className="flex h-max items-stretch py-2  text-left ">
        {info.row.original ? (
          <div className="flex h-full w-72 gap-4 px-2">
            <UserIcon className="h-6 w-6" />

            <Link
              href={`/discord-user/${info.row.original.teamDiscordUserTargetId}/activity`}
            >
              {info.getValue()}
            </Link>
          </div>
        ) : (
          <div className="w-72">
            <Skeleton />
          </div>
        )}
      </div>
    ),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("discordLatestActivityText", {
    header: () => <div className="w-80">Latest Activity</div>,
    cell: (info) => {
      const discordLatestActivityTime =
        info?.row?.original?.discordLatestActivityTime;
      const readableTime =
        discordLatestActivityTime &&
        new Date(discordLatestActivityTime).toLocaleString();
      return (
        <div className={` flex text-sm `}>
          {info.row.original?.discordLatestActivityText ? (
            <TruncatedString
              string={`Sent message: ${info.getValue()} at ${readableTime}`}
              maxLength={35}
            />
          ) : (
            <div className="px-2">
              <Skeleton />
            </div>
          )}
        </div>
      );
    },
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("discordMessageCount", {
    header: () => <div className="w-80">Message count</div>,
    cell: (info) => {
      return (
        <div className={`flex text-sm `}>
          {info.row.original ? (
            <TruncatedString
              string={info.getValue()?.toString() ?? ""}
              maxLength={35}
            />
          ) : (
            <div className="px-2">
              <Skeleton />
            </div>
          )}
        </div>
      );
    },
    footer: (info) => info.column.id,
  }),
];

// Fixed widths on headers are ESSENTIAL to ensure that the table is not janky

export const useCreateUserColumns = (
  customFields = [] as CustomField[],
  showEngagement = false as boolean,
  hasConnectedSocial = false as boolean
) => {
  const refetchCampaign = useCampaignRefetch();
  const { activeTeamAccount } = useTeamAccount();
  return useMemo(() => {
    const handleUpdates = (
      value: string,
      keyString: string,
      info: CellContext<TeamRenderTarget, string>,
      customField: CustomField
    ) => {
      if (!info.row.original) return Promise.resolve();
      const currentTargetContactId = info.row.original.id;
      const customFieldId = customField?.id;

      return trpc.customField.update
        .mutate({
          customFieldId: customFieldId,

          teamAccountId: activeTeamAccount.id,
          key: keyString,
          value: value,
          targetContactId: currentTargetContactId,
        })
        .catch(console.error);
    };
    const customFieldColumns = customFields.map((customField, index) => {
      const accessor = customField.key as keyof NonNullable<TeamRenderTarget>;
      const header = (customField.key as keyof TeamRenderTarget) ?? "N/A";
      const cell = (info: CellContext<TeamRenderTarget, string>) => {
        const handleUpdatesWithinCell = (value: string, keyString: string) => {
          return handleUpdates(value, keyString, info, customField);
        };
        const initialValue = info.row.original?.customFieldValues.find(
          (field) => field.customFieldId === customField.id
        )?.value_string;
        return (
          <div className="w-72 truncate px-2 text-sm ">
            {info.row.original ? (
              <EditableCell
                type="user"
                targetContactId={info.row.original.id}
                customFieldId={customField.id}
                initialValue={initialValue}
                keyString={accessor}
                updater={handleUpdatesWithinCell}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        );
      };
      return columnHelper.accessor(accessor, {
        header: () => {
          const handleDelete = async () => {
            await trpc.customField.deleteColumn.mutate({
              customFieldId: customField.id,
              teamAccountId: activeTeamAccount.id,
            });
            await refetchCampaign();
          };
          const headerText = (header as string) ?? "";
          return (
            <div className="flex gap-2">
              {headerText?.length > 30 ? (
                <div className="flex group">
                  <div className="flex gap-1">
                    <span className="w-60 truncate">{header}</span>
                  </div>
                  <div className="relative z-50 hidden group-hover:block">
                    <div className="absolute w-80 -ml-72 mt-8 bg-white px-1 text-sm">
                      {header}
                    </div>
                  </div>
                </div>
              ) : (
                header
              )}
              <button onClick={handleDelete}>
                <TrashIcon className="sm-icon hover:text-red-500" />
              </button>
            </div>
          );
        },
        cell,
        id: index.toString(),

        footer: () => null,
      });
    });

    const engagementColumn = showEngagement
      ? [
          columnHelper.accessor("dependencyStatusNumber", {
            header: () => <div className="w-32">Engagement</div>,
            cell: (info) => {
              const props = info.row.original;
              if (!props) return <Skeleton />;
              const depsStatusNumber = getInfoValueTeam<number | null>(info);
              const depsStatusObj = decodeStatusAsObject(
                depsStatusNumber,
                undefined
              );
              return (
                <div className={"w-32"}>
                  {info.row.original ? (
                    <LoadingWrapper
                      showSpinner={info.row.original.waitingOnDepsEval}
                    >
                      <div
                        className={`flex flex-nowrap w-full justify-center gap-8 gap-x-4 pl-2 pr-2`}
                      >
                        <div
                          className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}
                        >
                          <div className="flex gap-2">
                            <div
                              className={`group m-auto flex whitespace-nowrap rounded-md px-4 text-sm ${depsStatusObj.color}`}
                            >
                              {depsStatusObj.status}
                              <div className="relative z-40 opacity-0 group-hover:opacity-100">
                                <div className="absolute -ml-40 -mt-8 whitespace-nowrap bg-white px-1">
                                  {depsStatusObj.tooltip}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LoadingWrapper>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              );
            },
            footer: (info) => info.column.id,
            size: 400,
          }),
        ]
      : [];

    const socialColumns = hasConnectedSocial ? socialColumnsStatic : [];

    return [
      columnHelper.accessor("githubName", {
        header: () => <span>Name</span>,
        cell: (info) => (
          <div className="flex h-max items-stretch py-2  text-left ">
            <div className="w-12 px-3 text-right text-gray-400/50">
              {info.row.index + 1}
            </div>{" "}
            {info.row.original ? (
              <>
                {info.row.original.teamTargetContactId ? (
                  <Link
                    className="flex h-full w-72 gap-4 px-2 underline"
                    style={{
                      textDecorationColor: "#e0dede",
                      textUnderlineOffset: "0.25em",
                    }}
                    href={`/target/user/${info.row.original.teamTargetContactId}/commits/activity`}
                  >
                    <UserIcon className="h-6 w-6" />{" "}
                    {getInfoValueTeam<string>(info)}
                  </Link>
                ) : (
                  <div className="flex h-full w-72 gap-4 px-2">
                    <UserIcon className="h-6 w-6" />{" "}
                    {getInfoValueTeam<string>(info)}
                  </div>
                )}
              </>
            ) : (
              <div className="w-72">
                <Skeleton />
              </div>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      ...engagementColumn,
      columnHelper.accessor("dependencyStatusNumber", {
        header: () => <div className="w-32">Usage Status</div>,
        cell: (info) => {
          const props = info.row.original;
          if (!props) return <Skeleton />;
          const depsStatusNumber = getInfoValueTeam<number | null>(info);
          const simplifiedStatus = getSimplifiedStatus(depsStatusNumber);
          const simplifiedStatusObj = decodeStatusAsObject(
            simplifiedStatus,
            undefined
          );

          return (
            <div className={"w-32"}>
              {info.row.original ? (
                <LoadingWrapper
                  showSpinner={info.row.original.waitingOnDepsEval}
                >
                  <div
                    className={`flex flex-nowrap justify-center gap-8 gap-x-4 pl-2 pr-2 w-36`}
                  >
                    <div
                      className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}
                    >
                      <div className="flex gap-2">
                        <div
                          className={`relative group m-auto flex items-center gap-1 whitespace-nowrap rounded-md pl-4 pr-2 text-sm ${simplifiedStatusObj.color}`}
                        >
                          {simplifiedStatusObj.status}
                          <div
                            className={`relative z-20 opacity-0 group-hover:opacity-100`}
                          >
                            <div className="absolute -ml-40 -mt-7 whitespace-nowrap bg-white px-1">
                              {simplifiedStatusObj.tooltip}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
        size: 400,
      }),
      columnHelper.accessor("email", {
        header: "Email Addresses",
        cell: (info) => {
          const email = getInfoValueTeam<string>(info);
          return (
            <div className="w-72 truncate px-2 text-sm">
              {info.row.original ? (
                <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  {email && <EmailHandler email={email} />}
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("description", {
        header: () => <div className="w-80">Description</div>,
        cell: (info) => (
          <div className={`w-80 flex text-sm`}>
            {info.row.original ? (
              <TruncatedString
                string={getInfoValueTeam<string>(info) ?? ""}
                maxLength={35}
              />
            ) : (
              <div className="px-2">
                <Skeleton />
              </div>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("location", {
        header: "Location",
        cell: (info) => {
          return (
            <div className={`text-sm w-48 px-2`}>
              {info.row.original ? (
                getInfoValueTeam<string>(info)
              ) : (
                <div className="px-2">
                  <Skeleton />
                </div>
              )}
            </div>
          );
        },

        footer: (info) => info.column.id,
      }),

      columnHelper.accessor("topOrg", {
        header: "Organization",
        cell: (info) => {
          const org = getInfoValueTeam<string>(info);
          const avatarUrl = info.row.original?.topOrgAvatarUrl ?? "";

          return (
            <div className={`text-sm w-60 px-2`}>
              {info.row.original ? (
                <div className="flex gap-2 items-center justify-between">
                  {org && (
                    <>
                      <Link
                        className="flex items-center gap-2 hover:text-accent hover:underline"
                        href={`https://github.com/${org}`}
                        target="_blank"
                      >
                        {avatarUrl && (
                          <Image
                            width="32"
                            alt="org logo"
                            height="32"
                            className="invisible rounded-full border-2 border-gray-300 xl:visible"
                            src={avatarUrl ?? ""}
                          />
                        )}
                        {org}
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                      </Link>

                      <Link
                        className="text-xs text-blue-500 underline flex gap-1 items-center"
                        href={`/target/user/${info.row.original.teamTargetContactId}/projects/repositories`}
                        target="_blank"
                      >
                        more
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                      </Link>
                    </>
                  )}
                </div>
              ) : (
                <div className="px-2">
                  <Skeleton />
                </div>
              )}
            </div>
          );
        },

        footer: (info) => info.column.id,
      }),

      columnHelper.accessor("latestCommitDate", {
        header: () => (
          <div className="whitespace-nowrap flex items-center gap-2">
            Latest Commit
            <div className="group flex">
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute ml-0 -mt-4 whitespace-nowrap bg-white px-1 text-sm">
                  in past 6 weeks
                </div>
              </div>
            </div>
          </div>
        ),
        cell: (info) => {
          const latestCommitDate = info.row.original?.latestCommitDate;
          const formattedCommitDate = latestCommitDate
            ? new Date(latestCommitDate ?? 0).toLocaleDateString()
            : "no commits";

          return (
            <div className={"w-32"}>
              {info.row.original ? (
                <LoadingWrapper
                  showSpinner={!!info.row.original?.waitingOnEval}
                >
                  <div className={`flex justify-center text-sm px-2`}>
                    {formattedCommitDate}
                  </div>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },

        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("socialData", {
        header: () => <div className="text-left w-40">Socials</div>,
        cell: (info) => (
          <div className="px-2 pr-8 text-sm w-40">
            {info.row.original ? (
              <UserSocials
                mentionableUser={
                  getInfoValueTeam<{
                    twitterUsername?: string | null;
                    login?: string | null;
                    websiteUrl?: string | null;
                  }>(info) ?? {}
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("activity", {
        header: () => <div className=" text-left w-32">Overall activity</div>,
        cell: (info) => {
          const activity = (getInfoValueTeam<number>(info) ?? 0) * 100;
          const display =
            getInfoValueTeam<number>(info) !== undefined
              ? `${activity.toFixed()}%`
              : "N/A";
          return (
            <div className={"w-32"}>
              {info.row.original ? (
                <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={getColorFromPercentage(activity)}>
                    {display}
                  </CellWithPill>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("depActivity", {
        header: () => <div className=" text-left w-32">Relevant activity</div>,
        cell: (info) => {
          const activity = (getInfoValueTeam<number>(info) ?? 0) * 100;
          const activityTrend = info.row.original?.depActivityTrend;
          const upTrend = activityTrend === "up";
          const downTrend = activityTrend === "down";
          const display =
            getInfoValueTeam<number>(info) !== undefined ? (
              `${activity.toFixed()}%`
            ) : (
              <div className="group flex">
                N/A
                <div className="relative z-50 opacity-0 group-hover:opacity-100">
                  <div className="absolute -ml-40 -mt-6 whitespace-nowrap bg-white px-1 text-sm">
                    Please re-run the evaluation to see this score.
                  </div>
                </div>
              </div>
            );
          return (
            <div className={"w-32"}>
              {info.row.original ? (
                <LoadingWrapper
                  showSpinner={info.row.original.waitingOnDepsEval}
                >
                  {" "}
                  <div className="flex items-center justify-center ml-4 gap-2">
                    <CellWithPill color={getColorFromPercentage(activity)}>
                      {display}
                    </CellWithPill>

                    {downTrend && (
                      <ArrowTrendingDownIcon className="text-red-500 h-5 w-5" />
                    )}
                    {upTrend && (
                      <ArrowTrendingUpIcon className="text-green-500 h-5 w-5" />
                    )}
                  </div>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("languages", {
        header: () => (
          <div className="text-left whitespace-pre w-40">Top Languages</div>
        ),
        cell: (info) => (
          <div className={"w-40"}>
            {info.row.original ? (
              <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                <div className=" flex gap-2 px-2">
                  {getInfoValueTeam<string[]>(info)
                    ?.filter(getLangIcon)
                    .map((elem: string) => (
                      <div key={elem} className="h-6 w-6">
                        {getLangIcon(elem)}
                      </div>
                    ))}
                </div>
              </LoadingWrapper>
            ) : (
              <Skeleton />
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("reputation", {
        header: () => <div className=" text-left w-24">Reputation</div>,
        cell: (info) => {
          const display =
            getInfoValueTeam<number>(info) !== undefined
              ? `${nukeNegatives(
                  reputationHelper(getInfoValueTeam<number>(info) ?? 0)
                )} / 10`
              : "N/A";
          return (
            <div className={"w-24"}>
              {info.row.original ? (
                <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={"bg-warn"}>{display}</CellWithPill>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("commitCount", {
        header: () => (
          <div className="flex items-center gap-2 w-24">
            <span>Commits</span>
            <div className="group flex">
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute ml-0 -mt-4 whitespace-nowrap bg-white px-1 text-sm">
                  in past 6 weeks
                </div>
              </div>
            </div>
          </div>
        ),
        cell: (info) => {
          const display = getInfoValueTeam<number>(info) ?? "N/A";
          return (
            <div className={"w-24"}>
              {info.row.original ? (
                <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={"bg-accent-subtle text-white"}>
                    {display}
                  </CellWithPill>
                </LoadingWrapper>
              ) : (
                <Skeleton />
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("totalStars", {
        header: () => (
          <div className="w-16 whitespace-pre text-left">Stars</div>
        ),
        cell: (info) => (
          <>
            {info.row.original ? (
              <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                <CellWithPill color="bg-offwhite">
                  {getInfoValueTeam<number>(info)}
                </CellWithPill>
              </LoadingWrapper>
            ) : (
              <Skeleton />
            )}
          </>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("followers", {
        header: () => (
          <div className="whitespace-pre text-left w-24">Followers</div>
        ),
        footer: (info) => info.column.id,
        cell: (info) => (
          <div className={"w-24"}>
            {info.row.original ? (
              <CellWithPill color="bg-offwhite">
                {getInfoValueTeam<number>(info)}
              </CellWithPill>
            ) : (
              <Skeleton />
            )}
          </div>
        ),
      }),
      ...socialColumns,
      ...customFieldColumns,
    ];
  }, [
    customFields,
    activeTeamAccount.id,
  ]) as AccessorFnColumnDef<TeamRenderTarget>[];
};
