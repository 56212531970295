import { api } from "~/store/trpc";
import useCampaignListParams from "./useCampaignListParams";

export const useUpdateCustomFieldValue = (
  type: "user" | "repo",
  targetContactId: string,
  customFieldId: string,
  currentValue: string
) => {
  const utils = api.useUtils();
  const tableParams = useCampaignListParams();
  return (id: string) =>
    utils.targetContact.getTargetsByCampaignId.setInfiniteData(
      tableParams[type],
      (data) => {
        const newPages = data?.pages?.map((page) => {
          const newItems = page.items.map((item) => {
            if (item.id !== targetContactId) return item;
            const customFieldValue = item.customFieldValues?.map((field) => {
              if (field.customFieldId === customFieldId) {
                return { ...field, value_string: currentValue };
              }
              return field;
            });
            const newCustomFieldValue = !customFieldValue.find(
              (field) => field.customFieldId === customFieldId
            );
            if (newCustomFieldValue) {
              customFieldValue.push({
                customFieldId,
                value_string: currentValue,
                value_boolean: null,
                value_number: null,
                value_date: null,
                targetContactId,
                id,
              });
            }
            return { ...item, customFieldValue };
          });
          return { ...page, items: newItems };
        });
        const result = {
          ...data,
          pages: newPages ?? [],
          pageParams: [null],
        };
        return result;
      }
    );
};
