import UserCard from "~/components/campaigns/MainPage/Cards/UserCard";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TrackerProvider } from "~/providers/TrackerProvider";
import useFetchWhenDone from "~/hooks/useFetchWhenDone";
import { getScrollElement } from "~/components/campaigns/MainPage/SharedTable/utils";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
import { useCampaigns } from "~/providers/CampaignsProvider";
import useVirtualPadding from "~/hooks/useVirtualPadding";

const CampaignUsers = ({
  userTargetMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage,
}: {
  userTargetMap: TeamRenderTarget[];
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
}) => {
  const { campaigns } = useCampaigns();
  const rowCount = userTargetMap.length;

  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 300,
    overscan: 20,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  useFetchWhenDone(
    handleGetNextUserPage,
    virtualRows,
    rowCount,
    isFetchingNextUsersPage,
    hasNextUserPage
  );
  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  return (
    <div
      id="table-container"
      className="xl grid h-[calc(100vh_-_270px)] w-full grid-cols-1 gap-y-6 overflow-scroll @8xl:grid-cols-2"
    >
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {virtualRows.map((virtualRow) => {
        const targetContact = userTargetMap[virtualRow.index];

        const campaign = campaigns.find(
          (c) => c.id === targetContact?.campaignId
        );
        return (
          <TrackerProvider
            key={targetContact?.id ?? "key"}
            initialTracker={campaign?.trackers[0]}
          >
            <UserCard
              targetContact={targetContact as NonNullable<TeamRenderTarget>}
            />
          </TrackerProvider>
        );
      })}

      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </div>
  );
};
export default CampaignUsers;
