import CardActivityChart from "~/components/shared/ActivityChart";
import GenerateSharedActivityReportButton from "~/components/target/shared/details/GenerateSharedActivityReportButton";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type UserEvaluation } from "@openqlabs/drm-db";

export default function ChangesTab() {
  const latestEvaluation = useLatestEvaluation<UserEvaluation>();
  if (!latestEvaluation?.result) {
    return <div className="p-8 text-lg">Waiting for evaluation</div>;
  }

  return (
    <div className="pt-6">
      <CardActivityChart />
      <div className="flex items-center justify-center space-x-6 py-6">
        <GenerateSharedActivityReportButton type="user" title="Commit Summary">
          Summary
        </GenerateSharedActivityReportButton>
      </div>
    </div>
  );
}
