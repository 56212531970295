import { useCampaign } from "~/providers/CampaignProvider";
import Conditions from "./Conditions";

const SecondaryInsights = ({
  userCount,
  leads,
  newLeads,
  customers,
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const campaign = useCampaign();
  const today = new Date();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  const campaignEndDate = campaign?.endDate ?? campaign?.createdAt;

  const oneMonthPassed =
    today.getTime() - campaignEndDate.getTime() > thirtyDays;

  return (
    <div>
      <h3 className="py-6 text-xl font-semibold">Secondary Insights (ROI)</h3>
      {oneMonthPassed ? (
        <Conditions
          userCount={userCount}
          leads={leads}
          newLeads={newLeads}
          customers={customers}
        />
      ) : (
        <>
          Allow a one-month period to elapse following the end date of the
          campaign before gathering additional insights.
        </>
      )}
    </div>
  );
};
export default SecondaryInsights;
