import Link from "next/link";

import { Cell, type Table } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PlusIcon } from "@heroicons/react/24/outline";

import type { TeamRenderTarget } from "~/providers/TargetContactProvider";
import SkeletonRow from "./SkeletonRow";
import { useCampaign } from "~/providers/CampaignProvider";
import useFetchWhenDone from "~/hooks/useFetchWhenDone";
import useVirtualPadding from "~/hooks/useVirtualPadding";
import { getScrollElement } from "~/components/campaigns/MainPage/SharedTable/utils";
import { getCellContent } from "./renderHelper";
import { useCampaigns } from "~/providers/CampaignsProvider";

const Body = ({
  table,
  hasNextRepoPage,
  isFetchingNextReposPage,
  handleGetNextTeamPage,
}: {
  table: Table<TeamRenderTarget>;
  hasNextRepoPage?: boolean;
  isFetchingNextReposPage?: boolean;
  handleGetNextTeamPage: () => void;
}) => {
  const { rows } = table.getRowModel();
  const campaign = useCampaign();
  const rowCount = hasNextRepoPage ? rows.length + 1 : rows.length;
  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 43,
    overscan: 20,
  });
  const virtualRows = rowVirtualizer.getVirtualItems();

  useFetchWhenDone(
    handleGetNextTeamPage,
    virtualRows,
    rowCount,
    isFetchingNextReposPage,
    hasNextRepoPage
  );
  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  const { campaigns } = useCampaigns();
  const backuppedCampaign = campaign ?? campaigns[0];
  const getClassByIndex = (
    index: number,
    cell: Cell<TeamRenderTarget, unknown>
  ) => {
    const isFirstCell = cell.getIsAggregated();
    switch (index) {
      case 0:
        return "border-r  sticky cell 2 left-0 top-0 z-20 overflow-hidden border-r border-gray-100 bg-gray-50 text-left";

      case 1: {
        return isFirstCell
          ? "border-r z-10 border-gray-200 "
          : `border-r z-20 sticky  left-0 top-0 this overflow-hidden border-r border-gray-100 bg-gray-50 text-left`;
      }

      default:
        return "border-r z-10 border-gray-200";
    }
  };

  return (
    <tbody>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}

      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const isLoaderRow = virtualRow.index > rows.length - 1;
        const isLoading = isLoaderRow && isFetchingNextReposPage;
        const isLastRow = isLoaderRow && !isFetchingNextReposPage;

        if (isLoading) {
          return <SkeletonRow key="loading" numbering={virtualRow.index + 1} />;
        }

        if (isLastRow) {
          return null;
        }

        return (
          <tr className="z-30  border-y" key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              const cellContent = getCellContent(row, cell);
              return (
                <td className={getClassByIndex(index, cell)} key={cell.id}>
                  {cellContent}
                </td>
              );
            })}
          </tr>
        );
      })}
      <tr className="">
        {backuppedCampaign &&
          rows[0].getVisibleCells().map((_e, index) => {
            return (
              <td className="sticky left-0 z-20  bg-gray-100 p-3">
                <Link
                  className="w-full"
                  href={
                    backuppedCampaign
                      ? `/campaigns/${backuppedCampaign?.id}/add-contact`
                      : "/campaign/new"
                  }
                >
                  <PlusIcon
                    className={`h-6 w-6 ${
                      !index ? "text-gray-400" : "text-transparent"
                    }`}
                  />
                </Link>
              </td>
            );
          })}
      </tr>
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </tbody>
  );
};

export default Body;
