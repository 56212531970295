import { useState } from "react";
import DiscreetButton from "~/components/base/DiscreetButton";
import { Footer } from "~/components/layout/Card";
import ChangesTab from "~/components/campaigns/MainPage/Cards/UserCard/tabs/Changes";
import DependenciesTab from "~/components/campaigns/MainPage/Cards/UserCard/tabs/Dependencies";
import TabWrapper from "../../SharedCard/tabs/TabWrapper";
import { TeamRenderTarget } from "~/providers/TargetContactProvider";
import { TargetContactProvider } from "~/providers/TargetContactProvider";
import { decodeStatusAsObject } from "@openqlabs/utils";

enum CardTabs {
  Changes = "changes",
  Dependencies = "dependencies",
}

export default function Tabs({
  targetContact,
}: {
  targetContact: NonNullable<TeamRenderTarget>;
}) {
  const [showTab, setShowTab] = useState<CardTabs | null>(null);
  function handleClickTab(tab: CardTabs) {
    if (showTab === tab) setShowTab(null);
    else setShowTab(tab);
  }

  const firstDep = decodeStatusAsObject(
    targetContact.dependencyStatusNumber,
    undefined
  );

  /* const newLead = campaign?.lightMode
    ? null
    : Object.values(userStatusPerDependency ?? {}).some(
        (status) => status === "lead" || status === "lead inactive"
      ); */
  // currently only one dependency per tracker
  return (
    <Footer>
      <div className="flex">
        <DiscreetButton
          className={`w-full !rounded-b-none !rounded-tl-none !border-none text-sm font-normal hover:bg-offwhite ${
            showTab === CardTabs.Changes
              ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 "
              : ""
          }`}
          onClick={() => handleClickTab(CardTabs.Changes)}
        >
          Changes
        </DiscreetButton>
        <DiscreetButton
          className={`w-full !rounded-b-none !rounded-tr-none !border-none text-sm font-normal hover:bg-offwhite ${
            showTab === CardTabs.Dependencies
              ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 "
              : ""
          }`}
          onClick={() => handleClickTab(CardTabs.Dependencies)}
        >
          Dependencies{" "}
          <span
            className={`mx-2 whitespace-nowrap rounded-md ${firstDep.color} px-4 text-sm`}
          >
            {firstDep.status}
          </span>
        </DiscreetButton>
      </div>
      {!!showTab && (
        <TargetContactProvider id={targetContact.id}>
          <TabWrapper type="user">
            <div
              className={`${
                showTab === CardTabs.Changes ? "" : "max-h-0"
              } overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}
            >
              <ChangesTab />
            </div>
            <div
              className={`${
                showTab === CardTabs.Dependencies ? "" : "max-h-0"
              } overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}
            >
              <DependenciesTab />
            </div>
            <div
              className={`${
                showTab === CardTabs.Dependencies ? "" : "max-h-0"
              } overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}
            ></div>
          </TabWrapper>
        </TargetContactProvider>
      )}
    </Footer>
  );
}
