import Link from "next/link";
import { RouterInputs } from "~/utils/api";

import { pluralize } from "@openqlabs/utils";
import { useCampaigns } from "~/providers/CampaignsProvider";

export type CampaignListParamsType =
  RouterInputs["targetContact"]["getTargetsByCampaignId"];

const NoResults = ({
  activeHeaderMenu,
  hasUsers,
  hasTeams,
  loadedWithCampaigns,
  totalNumberOfTargetsWaiting,
  loadedWithNoCampaigns,
  searchTerm,
}: {
  activeHeaderMenu: "Teams" | "Contributors";
  hasTeams: boolean;
  hasUsers: boolean;
  loadedWithCampaigns: boolean;
  totalNumberOfTargetsWaiting: number;
  loadedWithNoCampaigns: boolean;
  searchTerm?: string;
}) => {
  const noTargets = activeHeaderMenu === "Teams" ? !hasTeams : !hasUsers;
  const campaignIds = useCampaigns().campaigns.map((c) => c.id) ?? [];
  const readableName = activeHeaderMenu === "Teams" ? "repository" : "user";
  return (
    <>
      {searchTerm ? (
        <>
          No {pluralize(readableName, 2)} found for the search term "
          {searchTerm}".
        </>
      ) : (
        <>
          {noTargets &&
            loadedWithCampaigns &&
            totalNumberOfTargetsWaiting !== 0 && (
              <span>
                We are adding your contacts to this campaign, waiting on{" "}
                {totalNumberOfTargetsWaiting}{" "}
                {pluralize("contact", totalNumberOfTargetsWaiting)}.
              </span>
            )}
          {noTargets &&
            loadedWithCampaigns &&
            totalNumberOfTargetsWaiting === 0 && (
              <>
                <Link
                  className="text-accent underline"
                  href={`/campaigns/${campaignIds[0] ?? ""}/add-contact`}
                >
                  Add a {pluralize(readableName, 2)} contact
                </Link>{" "}
                to populate {pluralize(readableName, 2)}. Have you just added
                one? Your contacts may not be added immediately, please reload
                the dashboard in 30 seconds.
              </>
            )}
          {noTargets && loadedWithNoCampaigns && (
            <>
              <Link
                className="text-accent underline"
                href={`/campaigns/new/add-contact`}
              >
                Add a campaign
              </Link>{" "}
              to begin running evaluations.
            </>
          )}
        </>
      )}
    </>
  );
};

export default NoResults;
